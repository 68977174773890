package com.uludi.pub.ui.dayeventslist

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.produceState
import androidx.compose.runtime.rememberCoroutineScope
import com.uludi.business.common.LoadState
import com.uludi.business.common.services.EventsService
import com.uludi.business.pub.services.PubManagerService
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDate

@Composable
fun DayEventsListPresenter(
    date: LocalDate,
    pubManagerService: PubManagerService,
    eventsService: EventsService,
    onAddEventClick: () -> Unit,
    onCloseClick: () -> Unit,
): DayEventsListScreen.State {
    val coroutineScope = rememberCoroutineScope()

    val events by produceState<DayEventsListScreen.EventsState>(DayEventsListScreen.EventsState.Loading) {
        pubManagerService
            .getSelectedPubEventsStream()
            .onEach { result ->
                value = when (result) {
                    is LoadState.Error -> DayEventsListScreen.EventsState.Error
                    LoadState.Loading -> DayEventsListScreen.EventsState.Loading
                    is LoadState.Success -> DayEventsListScreen.EventsState.Content(
                        result.data.filter { event -> event.date == date }
                    )
                }
            }
            .collect()
    }

    return DayEventsListScreen.State(
        eventsState = events,
        date = date,
    ) { event ->
        when (event) {
            DayEventsListScreen.UiEvent.CloseClicked -> onCloseClick()
            is DayEventsListScreen.UiEvent.EventDeleteClicked -> {
                coroutineScope.launch {
                    val pubId =
                        pubManagerService.getSelectedPubIdStream().first() ?: return@launch
                    eventsService.deletePubEvent(pubId = pubId, eventId = event.id)
                }
            }

            DayEventsListScreen.UiEvent.AddEventClicked -> onAddEventClick()
            DayEventsListScreen.UiEvent.RetryClicked ->
                coroutineScope.launch { pubManagerService.retryEventLoad() }
        }
    }
}