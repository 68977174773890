package com.uludi.pub.ui.home

import app.cash.molecule.RecompositionMode
import app.cash.molecule.launchMolecule
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.slot.ChildSlot
import com.arkivanov.decompose.router.slot.SlotNavigation
import com.arkivanov.decompose.router.slot.activate
import com.arkivanov.decompose.router.slot.childSlot
import com.arkivanov.decompose.router.slot.dismiss
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.lifecycle.coroutines.coroutineScope
import com.uludi.business.pub.services.PubManagerService
import com.uludi.pub.ui.daydetails.DayDetailsComponent
import com.uludi.pub.ui.daydetails.DayDetailsComponentParams
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable
import org.koin.core.annotation.Factory
import org.koin.core.annotation.InjectedParam
import org.koin.core.component.KoinComponent
import org.koin.core.component.get
import org.koin.core.parameter.parametersOf

@Factory
class HomeComponent(
    @InjectedParam componentContext: ComponentContext,
    private val pubManagerService: PubManagerService,
) : ComponentContext by componentContext, KoinComponent {
    private val daySheetNavigation = SlotNavigation<DaySheetConfig>()

    val childSlot: Value<ChildSlot<*, DayDetailsComponent>> =
        childSlot(
            daySheetNavigation,
            serializer = DaySheetConfig.serializer(),
            handleBackButton = true,
        ) { config, childComponentContext ->
            DayDetailsComponent(
                get {
                    parametersOf(
                        DayDetailsComponentParams(
                            date = config.date,
                            onClose = { daySheetNavigation.dismiss() },
                            componentContext = childComponentContext,
                        )
                    )
                }
            )
        }

    val state = coroutineScope().launchMolecule(RecompositionMode.Immediate) {
        HomePresenter(
            pubManagerService = pubManagerService,
            showDayDetails = { date ->
                daySheetNavigation.activate(DaySheetConfig(date))
            },
            hideDayDetails = {
                daySheetNavigation.dismiss()
            }
        )
    }

    @Serializable
    private data class DaySheetConfig(
        val date: LocalDate,
    )
}