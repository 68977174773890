package com.uludi.pub.ui.addevent

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.uludi.bard.components.ErrorWidget
import com.uludi.bard.components.Event
import com.uludi.bard.components.LoadingWidget
import com.uludi.bard.components.SideSheet
import com.uludi.bard.components.SideSheetTopBar
import com.uludi.bard.components.Spacer
import com.uludi.business.common.services.Event
import com.uludi.common.parcelize.LocalDateParceler
import com.uludi.common.ui.mappers.toEventInfo
import com.uludi.pub.ui.resources.LocalPubResources
import kotlinx.datetime.LocalDate
import kotlinx.datetime.format
import kotlinx.datetime.format.FormatStringsInDatetimeFormats
import kotlinx.datetime.format.byUnicodePattern
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.stringResource

data class AddEventScreen(
    val date: LocalDate
)  {
    data class State(
        val date: LocalDate,
        val eventsState: EventsState = EventsState.Loading,
        val eventSink: (UiEvent) -> Unit,
    )

    sealed class EventsState {
        data object Loading : EventsState()
        data object Error : EventsState()
        data class Content(
            val events: List<Event>
        ) : EventsState()
    }

    sealed class UiEvent {
        data object BackClicked : UiEvent()
        data object CloseClicked : UiEvent()
        data object RetryLoadingClicked : UiEvent()
        data class EventClicked(val id: Int) : UiEvent()
    }
}

@Composable
fun AddEventSmartScreen(
    component: AddEventComponent
) {
    val state by component.state.collectAsState()
    AddEventScreen(state)
}

@OptIn(ExperimentalResourceApi::class, FormatStringsInDatetimeFormats::class)
@Composable
fun AddEventScreen(state: AddEventScreen.State, modifier: Modifier = Modifier) {
    val pubResources = LocalPubResources.current

    SideSheet(modifier,
        topBar = {
            SideSheetTopBar(
                title = { Text(stringResource(pubResources.strings.addEventTitle)) },
                onDismiss = { state.eventSink(AddEventScreen.UiEvent.CloseClicked) },
                navigationIcon = {
                    IconButton({ state.eventSink(AddEventScreen.UiEvent.BackClicked) }) {
                        Icon(Icons.AutoMirrored.Filled.ArrowBack, null)
                    }
                },
            )
        }
    ) {
        Column(modifier.fillMaxWidth()) {
            LazyColumn {
                item { Spacer.V.L() }
                item {
                    Text(
                        stringResource(
                            pubResources.strings.addEventEventsHeader,
                            state.date.format(LocalDate.Format { byUnicodePattern("d/M") })
                        ),
                        style = MaterialTheme.typography.headlineMedium,
                        modifier = Modifier.padding(horizontal = 16.dp),
                    )
                }
                when (state.eventsState) {
                    AddEventScreen.EventsState.Loading -> item { LoadingWidget(Modifier.fillMaxSize()) }
                    AddEventScreen.EventsState.Error -> item {
                        ErrorWidget(Modifier.fillMaxSize()) {
                            state.eventSink(AddEventScreen.UiEvent.RetryLoadingClicked)
                        }
                    }

                    is AddEventScreen.EventsState.Content ->
                        itemsIndexed(
                            items = state.eventsState.events,
                            key = { _, item -> item.id }
                        ) { index, item ->
                            Event(item.toEventInfo()) {
                                state.eventSink(AddEventScreen.UiEvent.EventClicked(item.id))
                            }
                            if (index != state.eventsState.events.lastIndex) {
                                HorizontalDivider(
                                    Modifier.padding(horizontal = 16.dp).fillMaxWidth()
                                )
                            }
                        }
                }
            }
        }
    }
}

