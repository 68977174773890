@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package uludi.pub.app.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.StringResource

@ExperimentalResourceApi
private object String0 {
  public val add_event_events_header: StringResource by 
      lazy { init_add_event_events_header() }

  public val add_event_title: StringResource by 
      lazy { init_add_event_title() }

  public val day_details_delete_add_event_button_text: StringResource by 
      lazy { init_day_details_delete_add_event_button_text() }

  public val day_details_delete_event_dialog_cancel: StringResource by 
      lazy { init_day_details_delete_event_dialog_cancel() }

  public val day_details_delete_event_dialog_confirm: StringResource by 
      lazy { init_day_details_delete_event_dialog_confirm() }

  public val day_details_delete_event_dialog_text: StringResource by 
      lazy { init_day_details_delete_event_dialog_text() }

  public val day_details_delete_event_dialog_title: StringResource by 
      lazy { init_day_details_delete_event_dialog_title() }

  public val day_details_title: StringResource by 
      lazy { init_day_details_title() }

  public val error_message: StringResource by 
      lazy { init_error_message() }

  public val error_retry_button_label: StringResource by 
      lazy { init_error_retry_button_label() }

  public val login_email_placeholder: StringResource by 
      lazy { init_login_email_placeholder() }

  public val login_invalid_credentials_text: StringResource by 
      lazy { init_login_invalid_credentials_text() }

  public val login_password_placeholder: StringResource by 
      lazy { init_login_password_placeholder() }

  public val login_submit_button_text: StringResource by 
      lazy { init_login_submit_button_text() }

  public val retry_button_label: StringResource by 
      lazy { init_retry_button_label() }
}

@ExperimentalResourceApi
internal val Res.string.add_event_events_header: StringResource
  get() = String0.add_event_events_header

@ExperimentalResourceApi
private fun init_add_event_events_header(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:add_event_events_header", "add_event_events_header",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "values-es/strings.xml"),
      org.jetbrains.compose.resources.ResourceItem(setOf(), "values/strings.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.string.add_event_title: StringResource
  get() = String0.add_event_title

@ExperimentalResourceApi
private fun init_add_event_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_event_title", "add_event_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "values-es/strings.xml"),
      org.jetbrains.compose.resources.ResourceItem(setOf(), "values/strings.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.string.day_details_delete_add_event_button_text: StringResource
  get() = String0.day_details_delete_add_event_button_text

@ExperimentalResourceApi
private fun init_day_details_delete_add_event_button_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:day_details_delete_add_event_button_text", "day_details_delete_add_event_button_text",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "values-es/strings.xml"),
      org.jetbrains.compose.resources.ResourceItem(setOf(), "values/strings.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.string.day_details_delete_event_dialog_cancel: StringResource
  get() = String0.day_details_delete_event_dialog_cancel

@ExperimentalResourceApi
private fun init_day_details_delete_event_dialog_cancel(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:day_details_delete_event_dialog_cancel", "day_details_delete_event_dialog_cancel",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "values-es/strings.xml"),
      org.jetbrains.compose.resources.ResourceItem(setOf(), "values/strings.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.string.day_details_delete_event_dialog_confirm: StringResource
  get() = String0.day_details_delete_event_dialog_confirm

@ExperimentalResourceApi
private fun init_day_details_delete_event_dialog_confirm(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:day_details_delete_event_dialog_confirm", "day_details_delete_event_dialog_confirm",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "values-es/strings.xml"),
      org.jetbrains.compose.resources.ResourceItem(setOf(), "values/strings.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.string.day_details_delete_event_dialog_text: StringResource
  get() = String0.day_details_delete_event_dialog_text

@ExperimentalResourceApi
private fun init_day_details_delete_event_dialog_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:day_details_delete_event_dialog_text", "day_details_delete_event_dialog_text",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "values-es/strings.xml"),
      org.jetbrains.compose.resources.ResourceItem(setOf(), "values/strings.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.string.day_details_delete_event_dialog_title: StringResource
  get() = String0.day_details_delete_event_dialog_title

@ExperimentalResourceApi
private fun init_day_details_delete_event_dialog_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:day_details_delete_event_dialog_title", "day_details_delete_event_dialog_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "values-es/strings.xml"),
      org.jetbrains.compose.resources.ResourceItem(setOf(), "values/strings.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.string.day_details_title: StringResource
  get() = String0.day_details_title

@ExperimentalResourceApi
private fun init_day_details_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:day_details_title", "day_details_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "values-es/strings.xml"),
      org.jetbrains.compose.resources.ResourceItem(setOf(), "values/strings.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.string.error_message: StringResource
  get() = String0.error_message

@ExperimentalResourceApi
private fun init_error_message(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:error_message", "error_message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "values-es/strings.xml"),
      org.jetbrains.compose.resources.ResourceItem(setOf(), "values/strings.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.string.error_retry_button_label: StringResource
  get() = String0.error_retry_button_label

@ExperimentalResourceApi
private fun init_error_retry_button_label(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:error_retry_button_label", "error_retry_button_label",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "values-es/strings.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.string.login_email_placeholder: StringResource
  get() = String0.login_email_placeholder

@ExperimentalResourceApi
private fun init_login_email_placeholder(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_email_placeholder", "login_email_placeholder",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "values-es/strings.xml"),
      org.jetbrains.compose.resources.ResourceItem(setOf(), "values/strings.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.string.login_invalid_credentials_text: StringResource
  get() = String0.login_invalid_credentials_text

@ExperimentalResourceApi
private fun init_login_invalid_credentials_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_invalid_credentials_text", "login_invalid_credentials_text",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "values-es/strings.xml"),
      org.jetbrains.compose.resources.ResourceItem(setOf(), "values/strings.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.string.login_password_placeholder: StringResource
  get() = String0.login_password_placeholder

@ExperimentalResourceApi
private fun init_login_password_placeholder(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_password_placeholder", "login_password_placeholder",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "values-es/strings.xml"),
      org.jetbrains.compose.resources.ResourceItem(setOf(), "values/strings.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.string.login_submit_button_text: StringResource
  get() = String0.login_submit_button_text

@ExperimentalResourceApi
private fun init_login_submit_button_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_submit_button_text", "login_submit_button_text",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "values-es/strings.xml"),
      org.jetbrains.compose.resources.ResourceItem(setOf(), "values/strings.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.string.retry_button_label: StringResource
  get() = String0.retry_button_label

@ExperimentalResourceApi
private fun init_retry_button_label(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:retry_button_label", "retry_button_label",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(), "values/strings.xml"),
    )
)
