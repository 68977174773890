package com.uludi.business.common.services

import org.koin.core.annotation.ComponentScan
import org.koin.core.annotation.Module
import org.koin.dsl.module

internal expect fun org.koin.core.module.Module.platformServicesModule()

@Module
@ComponentScan
class CommonServicesModule

val servicesModule = module {
    platformServicesModule()
}