package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val com_uludi_business_common_CoroutinesModule : Module = module {
	val moduleInstance = com.uludi.business.common.CoroutinesModule()
	factory(qualifier=org.koin.core.qualifier.StringQualifier("Default")) { moduleInstance.provideIoCoroutineScope() } bind(kotlinx.coroutines.CoroutineScope::class)
	factory(qualifier=org.koin.core.qualifier.StringQualifier("Main")) { moduleInstance.provideMainCoroutineContext() } bind(kotlin.coroutines.CoroutineContext::class)
}
public val com.uludi.business.common.CoroutinesModule.module : org.koin.core.module.Module get() = com_uludi_business_common_CoroutinesModule