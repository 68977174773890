package com.uludi.pub.ui.dayeventslist

import app.cash.molecule.RecompositionMode
import app.cash.molecule.launchMolecule
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.coroutines.coroutineScope
import com.uludi.business.common.services.EventsService
import com.uludi.business.pub.services.PubManagerService
import kotlinx.datetime.LocalDate
import org.koin.core.annotation.Factory
import org.koin.core.annotation.InjectedParam

data class DayEventsListComponentParams(
    val componentContext: ComponentContext,
    val date: LocalDate,
    val onClose: () -> Unit,
    val onAddEventClick: () -> Unit,
)

@Factory
class DayEventsListComponent(
    @InjectedParam private val params: DayEventsListComponentParams,
    private val pubManagerService: PubManagerService,
    private val eventsService: EventsService,
) : ComponentContext by params.componentContext {
    val state = coroutineScope().launchMolecule(RecompositionMode.Immediate) {
        DayEventsListPresenter(
            date = params.date,
            pubManagerService = pubManagerService,
            eventsService = eventsService,
            onAddEventClick = { params.onAddEventClick() },
            onCloseClick = { params.onClose() }
        )
    }
}