package com.uludi.pub.ui.common

import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp

@Composable
internal fun Modifier.topBarPadding(): Modifier {
    return this.then(Modifier.padding(top = 104.dp))
}