import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import com.uludi.pub.BuildKonfig
import com.uludi.pub.app.PubApp
import org.jetbrains.skiko.wasm.onWasmReady

@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    onWasmReady {
        CanvasBasedWindow("ComposeTarget") {
            PubApp(
                environment = BuildKonfig.environment,
                isDebug = BuildKonfig.isDebug,
            )
        }
    }
}