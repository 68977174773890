package com.uludi.business.pub.services

import com.russhwolf.settings.Settings
import org.koin.core.annotation.ComponentScan
import org.koin.core.annotation.Factory
import org.koin.core.annotation.Module

@Module
@ComponentScan
class PubServicesModule {
    @Factory
    fun provideSettings(): Settings = Settings()
}