@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package uludi.pub.app.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.ExperimentalResourceApi

@ExperimentalResourceApi
private object Drawable0 {
  public val icon: DrawableResource by 
      lazy { init_icon() }

  public val logo: DrawableResource by 
      lazy { init_logo() }
}

@ExperimentalResourceApi
internal val Res.drawable.icon: DrawableResource
  get() = Drawable0.icon

@ExperimentalResourceApi
private fun init_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(), "drawable/icon.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.drawable.logo: DrawableResource
  get() = Drawable0.logo

@ExperimentalResourceApi
private fun init_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(), "drawable/logo.xml"),
    )
)
