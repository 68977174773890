package com.uludi.pub.ui.daydetails

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.arkivanov.decompose.extensions.compose.stack.Children
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.value.Value
import com.uludi.pub.ui.addevent.AddEventSmartScreen
import com.uludi.pub.ui.dayeventslist.DayEventsListSmartScreen

@Composable
fun DayDetailsSmartScreen(
    component: DayDetailsComponent,
) {
    DayDetailsScreen(component.childStack)
}

@Composable
fun DayDetailsScreen(
    childStack: Value<ChildStack<*, DayDetailsComponent.Child>>,
    modifier: Modifier = Modifier,
) {
    Children(childStack, modifier = modifier) {
        when (val child = it.instance) {
            is DayDetailsComponent.Child.EventsList -> DayEventsListSmartScreen(child.component)
            is DayDetailsComponent.Child.AddEvent -> AddEventSmartScreen(child.component)
        }
    }
}