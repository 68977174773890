package com.uludi.pub.ui.home

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.produceState
import androidx.compose.runtime.rememberCoroutineScope
import com.uludi.business.common.LoadState
import com.uludi.business.pub.services.PubManagerService
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDate

@Composable
internal fun HomePresenter(
    pubManagerService: PubManagerService,
    showDayDetails: (date: LocalDate) -> Unit,
    hideDayDetails: () -> Unit,
): HomeScreen.State {
    val coroutineScope = rememberCoroutineScope()
    val events by produceState<HomeScreen.PubEventsState>(HomeScreen.PubEventsState.Loading) {
        pubManagerService
            .getSelectedPubEventsStream()
            .onEach { result ->
                value = when (result) {
                    is LoadState.Error -> HomeScreen.PubEventsState.Error
                    LoadState.Loading -> HomeScreen.PubEventsState.Loading
                    is LoadState.Success -> HomeScreen.PubEventsState.Content(
                        events = result.data.groupBy { event -> event.date }
                    )
                }
            }
            .collect()
    }
    return HomeScreen.State(
        eventsState = events,
    ) { event ->
        when (event) {
            is HomeScreen.UiEvent.DayClicked -> {
                showDayDetails(event.date)
            }

            HomeScreen.UiEvent.RetryLoadClicked -> {
                coroutineScope.launch { pubManagerService.retryEventLoad() }
            }

            HomeScreen.UiEvent.CloseDayDetails -> hideDayDetails()
        }
    }
}