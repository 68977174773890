package com.uludi.pub.ui.resources

import androidx.compose.runtime.compositionLocalOf
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.StringResource

//TODO: Note this is only needed because multimodule resources are not yet supported.

@ExperimentalResourceApi
val LocalPubResources = compositionLocalOf<PubResources> {
    error("No pub resources provided")
}

@ExperimentalResourceApi
interface PubResources {
    val strings: Strings

    @ExperimentalResourceApi
    interface Strings {
        val loginEmailPlaceholder: StringResource
        val loginPasswordPlaceholder: StringResource
        val loginSubmitButtonText: StringResource
        val loginInvalidCredentialsText: StringResource
        val dayDetailsTitle: StringResource
        val dayDetailsDeleteEventDialogTitle: StringResource
        val dayDetailsDeleteEventDialogText: StringResource
        val dayDetailsDeleteEventDialogConfirm: StringResource
        val dayDetailsDeleteEventDialogCancel: StringResource
        val dayDetailsAddEventButtonText: StringResource
        val addEventTitle: StringResource
        val addEventEventsHeader: StringResource
    }
}