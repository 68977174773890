package com.uludi.pub.ui.login

import app.cash.molecule.RecompositionMode
import app.cash.molecule.launchMolecule
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.coroutines.coroutineScope
import com.uludi.business.pub.services.AuthService
import org.koin.core.annotation.Factory
import org.koin.core.annotation.InjectedParam

data class LoginComponentParams(
    val onLoggedIn: () -> Unit,
)

@Factory
class LoginComponent(
    @InjectedParam componentContext: ComponentContext,
    @InjectedParam params: LoginComponentParams,
    private val authService: AuthService,
) : ComponentContext by componentContext {
    val state = coroutineScope().launchMolecule(RecompositionMode.Immediate) {
        LoginPresenter(
            authService = authService,
            onLoggedIn = { params.onLoggedIn() }
        )
    }
}