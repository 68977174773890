package com.uludi.pub.ui.layout

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Logout
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import com.arkivanov.decompose.extensions.compose.stack.Children
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.value.Value
import com.uludi.bard.components.Spacer
import com.uludi.business.common.services.Pub
import com.uludi.business.pub.services.PubManager
import com.uludi.common.resources.LocalCommonResources
import com.uludi.pub.ui.home.HomeSmartScreen
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource

data object PubLayout {
    data class State(
        val dataState: DataState = DataState.Loading,
        val eventSink: (UiEvent) -> Unit,
    )

    sealed class DataState {
        data object Loading : DataState()
        data object NotAuthenticated : DataState()
        data class Authenticated(
            val pubManager: PubManager,
        ) : DataState()

        data object Error : DataState()
    }

    @Stable //TODO: Needed due to some K2 compiler bug. Remove.
    sealed class UiEvent {
        data object SignOutClicked : UiEvent()
        data class PubClicked(val pub: Pub) : UiEvent()
    }
}

@Composable
fun PubSmartLayout(pubLayoutComponent: PubLayoutComponent) {
    val state by pubLayoutComponent.state.collectAsState()
    PubLayout(state, pubLayoutComponent.childStack)
}

@Composable
private fun PubLayout(
    state: PubLayout.State,
    childStack: Value<ChildStack<*, PubLayoutComponent.Child>>,
    modifier: Modifier = Modifier
) {
    Surface(modifier = modifier) {
        Box(Modifier.fillMaxSize()) {
            if (state.dataState is PubLayout.DataState.Authenticated) {
                TopBar(
                    pubManager = state.dataState.pubManager,
                    onSignOutClick = { state.eventSink(PubLayout.UiEvent.SignOutClicked) },
                    onPubClick = { state.eventSink(PubLayout.UiEvent.PubClicked(it)) },
                    modifier = Modifier.fillMaxWidth()
                )
            }
            Children(childStack) {
                when (val child = it.instance) {
                    is PubLayoutComponent.Child.Home -> HomeSmartScreen(child.component)
                }
            }
        }
    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun TopBar(
    pubManager: PubManager,
    onSignOutClick: () -> Unit,
    onPubClick: (Pub) -> Unit,
    modifier: Modifier = Modifier,
) {
    val commonResources = LocalCommonResources.current
    Box(modifier.height(104.dp).padding(horizontal = 32.dp)) {
        Box(Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
            Image(
                painter = painterResource(commonResources.drawables.logo),
                contentDescription = null,
                modifier = Modifier.height(67.5.dp),
                contentScale = ContentScale.FillHeight,
            )
        }
        Row(
            Modifier.fillMaxSize(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            if (pubManager.pubs.size > 1) {
                PubSelector(pubManager, onPubClick)
            } else {
                PubDisplayer(pubManager)
            }
            Spacer(Modifier.weight(1f))
            Text(
                text = pubManager.email,
                style = MaterialTheme.typography.labelLarge,
            )
            Spacer.H.M()
            IconButton({
                onSignOutClick()
            }) {
                Icon(Icons.AutoMirrored.Filled.Logout, null)
            }
        }
    }
}

@Composable
private fun PubSelector(
    pubManager: PubManager,
    onPubClick: (Pub) -> Unit,
) {
    var pubSelectorVisible by remember { mutableStateOf(false) }
    Box {
        OutlinedButton(
            contentPadding = ButtonDefaults.ButtonWithIconContentPadding,
            onClick = { pubSelectorVisible = true }
        ) {
            Icon(
                Icons.Default.ArrowDropDown,
                null,
                modifier = Modifier.size(ButtonDefaults.IconSize)
            )
            Spacer(Modifier.size(ButtonDefaults.IconSpacing))
            Text(pubManager.selectedPub.name)
        }
        DropdownMenu(
            expanded = pubSelectorVisible,
            onDismissRequest = { pubSelectorVisible = false }) {
            pubManager.pubs.forEach { pub ->
                DropdownMenuItem(
                    text = { Text(pub.name) },
                    onClick = {
                        onPubClick(pub)
                        pubSelectorVisible = false
                    }
                )
            }
        }
    }
}

@Composable
private fun PubDisplayer(
    pubManager: PubManager
) {
    Text(
        pubManager.selectedPub.name,
        style = MaterialTheme.typography.labelLarge,
        color = MaterialTheme.colorScheme.primary
    )
}