package com.uludi.pub.ui.home

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ExperimentalLayoutApi
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.pager.PagerState
import androidx.compose.foundation.pager.rememberPagerState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ChevronLeft
import androidx.compose.material.icons.filled.ChevronRight
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.input.key.type
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import com.arkivanov.decompose.extensions.compose.subscribeAsState
import com.arkivanov.decompose.router.slot.ChildSlot
import com.uludi.bard.components.ErrorWidget
import com.uludi.bard.components.ModalSideSheet
import com.uludi.bard.components.SideSheetValue
import com.uludi.bard.components.rememberSideSheetState
import com.uludi.business.common.services.Event
import com.uludi.pub.ui.common.topBarPadding
import com.uludi.pub.ui.daydetails.DayDetailsComponent
import com.uludi.pub.ui.daydetails.DayDetailsSmartScreen
import io.wojciechosak.calendar.config.DayState
import io.wojciechosak.calendar.config.rememberCalendarState
import io.wojciechosak.calendar.utils.today
import io.wojciechosak.calendar.view.CalendarView
import io.wojciechosak.calendar.view.HorizontalCalendarView
import kotlinx.coroutines.launch
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.LocalDate
import kotlinx.datetime.Month

data object HomeScreen {
    data class State(
        val eventsState: PubEventsState = PubEventsState.Loading,
        val selectedDate: LocalDate? = null,
        val eventsSink: (UiEvent) -> Unit,
    )

    sealed class PubEventsState {
        data object Loading : PubEventsState()
        data object Error : PubEventsState()
        data class Content(
            val events: Map<LocalDate, List<Event>>,
        ) : PubEventsState()
    }

    sealed class UiEvent {
        data class DayClicked(val date: LocalDate) : UiEvent()
        data object RetryLoadClicked : UiEvent()
        data object CloseDayDetails : UiEvent()
    }
}

@Composable
fun HomeSmartScreen(component: HomeComponent) {
    val state by component.state.collectAsState()
    val slot by component.childSlot.subscribeAsState()
    HomeScreen(state, slot)
}

@Composable
fun HomeScreen(
    state: HomeScreen.State,
    childSlot: ChildSlot<*, DayDetailsComponent>,
    modifier: Modifier = Modifier,
) {
    Box(modifier = modifier
        .onPreviewKeyEvent {
            if (it.type == KeyEventType.KeyDown && it.key == Key.Escape) {
                state.eventsSink(HomeScreen.UiEvent.CloseDayDetails)
                true
            } else {
                false
            }
        }
        .fillMaxSize()
        .topBarPadding(), contentAlignment = Alignment.Center
    ) {
        when (state.eventsState) {
            HomeScreen.PubEventsState.Error -> ErrorWidget { state.eventsSink(HomeScreen.UiEvent.RetryLoadClicked) }
            HomeScreen.PubEventsState.Loading,
            is HomeScreen.PubEventsState.Content -> {
                Calendar(state)
                if (state.eventsState == HomeScreen.PubEventsState.Loading) {
                    Dialog(onDismissRequest = {}) {
                        CircularProgressIndicator()
                    }
                }
            }
        }
    }
    val sheetState = rememberSideSheetState(initialValue = SideSheetValue.Closed)
    val child by derivedStateOf { childSlot.child?.instance }
    LaunchedEffect(child) {
        if (child != null) {
            sheetState.open()
        } else {
            sheetState.close()
        }
    }
    ModalSideSheet(
        sideSheetState = sheetState,
        onDismissRequest = { state.eventsSink(HomeScreen.UiEvent.CloseDayDetails) }
    ) {
        child?.let {
            DayDetailsSmartScreen(it)
        }
    }
}

@OptIn(ExperimentalFoundationApi::class)
@Composable
private fun Calendar(
    state: HomeScreen.State,
    modifier: Modifier = Modifier,
) {
    val pagerState = rememberPagerState(
        initialPage = 100000 / 2,
        pageCount = { 100000 }
    )
    HorizontalCalendarView(
        pagerState = pagerState,
        startDate = LocalDate.today(),
        modifier = modifier
            .widthIn(max = 928.dp)
            .background(
                MaterialTheme.colorScheme.surfaceContainerLowest,
                RoundedCornerShape(16.dp)
            )
            .padding(30.dp),
    ) { monthOffset ->
        val events =
            remember(state) { (state.eventsState as? HomeScreen.PubEventsState.Content)?.events }
        CalendarView(
            config = rememberCalendarState(
                startDate = LocalDate.today(),
                monthOffset = monthOffset,
            ),
            header = { month, year ->
                HeaderView(month, year, pagerState)
            },
            dayOfWeekLabel = { dayOfWeek ->
                DayOfWeekView(dayOfWeek)
            },
            day = { day ->
                DayView(day, events = events?.get(day.date) ?: emptyList()) {
                    state.eventsSink(HomeScreen.UiEvent.DayClicked(day.date))
                }
            }
        )
    }
}

@Composable
private fun DayOfWeekView(dayOfWeek: DayOfWeek) {
    Box(Modifier.padding(bottom = 12.dp), contentAlignment = Alignment.TopCenter) {
        Text("$dayOfWeek", style = MaterialTheme.typography.bodyLarge)
    }
}

@OptIn(ExperimentalFoundationApi::class)
@Composable
private fun HeaderView(
    month: Month,
    year: Int,
    pagerState: PagerState,
) {
    val coroutineScope = rememberCoroutineScope()
    Row(Modifier.fillMaxWidth().padding(bottom = 12.dp)) {
        Text(
            "$month $year",
            style = MaterialTheme.typography.displaySmall,
            modifier = Modifier.weight(1f)
        )
        IconButton(
            enabled = pagerState.canScrollBackward,
            onClick = {
                coroutineScope.launch {
                    pagerState.animateScrollToPage(
                        pagerState.currentPage - 1
                    )
                }
            }
        ) {
            Icon(Icons.Default.ChevronLeft, null)
        }
        Spacer(Modifier.widthIn(8.dp))
        IconButton(
            enabled = pagerState.canScrollForward,
            onClick = {
                coroutineScope.launch {
                    pagerState.animateScrollToPage(
                        pagerState.currentPage + 1
                    )
                }
            }
        ) {
            Icon(Icons.Default.ChevronRight, null)
        }
    }
}

@OptIn(ExperimentalLayoutApi::class)
@Composable
private fun DayView(
    day: DayState,
    events: List<Event>,
    onClick: () -> Unit,
) {
    Column(
        modifier = Modifier
            .clickable(onClick = onClick)
            .then(
                if (day.isForPreviousMonth || day.isForNextMonth)
                    Modifier.background(MaterialTheme.colorScheme.surfaceBright)
                else if (day.date == LocalDate.today())
                    Modifier.background(MaterialTheme.colorScheme.primaryContainer)
                else Modifier
            )
            .border(1.dp, MaterialTheme.colorScheme.outline)
            .padding(top = 10.dp)
            .width(124.dp)
            .height(100.dp),
    ) {
        Text(
            text = "${day.date.dayOfMonth}",
            style = MaterialTheme.typography.bodyLarge,
            color = if (day.isForPreviousMonth || day.isForNextMonth) {
                MaterialTheme.colorScheme.outlineVariant
            } else Color.Unspecified,
            modifier = Modifier.fillMaxWidth(),
            textAlign = TextAlign.Center
        )
        Spacer(Modifier.height(8.dp))
        FlowRow(
            horizontalArrangement = Arrangement.spacedBy(8.dp),
            verticalArrangement = Arrangement.spacedBy(4.dp),
            modifier = Modifier.padding(horizontal = 8.dp)
        ) {
            repeat(events.size) {
                Box(
                    Modifier.background(MaterialTheme.colorScheme.secondary, CircleShape)
                        .size(12.dp)
                )
            }
        }
    }
}
