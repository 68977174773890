package com.uludi.pub.ui.layout

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.produceState
import androidx.compose.runtime.rememberCoroutineScope
import com.uludi.business.common.LoadState
import com.uludi.business.pub.services.AuthService
import com.uludi.business.pub.services.PubManagerService
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch

@Composable
fun PubLayoutPresenter(
    pubManagerService: PubManagerService,
    authService: AuthService
): PubLayout.State {
    val coroutineScope = rememberCoroutineScope()

    val dataState by produceState<PubLayout.DataState>(PubLayout.DataState.Loading) {
        pubManagerService.getPubManagerStream()
            .onEach {
                value = when (it) {
                    is LoadState.Error -> PubLayout.DataState.Error
                    LoadState.Loading -> PubLayout.DataState.Loading
                    is LoadState.Success -> PubLayout.DataState.Authenticated(it.data)
                    null -> PubLayout.DataState.NotAuthenticated
                }
            }
            .collect()
    }

    return PubLayout.State(
        dataState = dataState
    ) { event ->
        when (event) {
            PubLayout.UiEvent.SignOutClicked ->
                coroutineScope.launch { authService.signOut() }

            is PubLayout.UiEvent.PubClicked ->
                pubManagerService.setSelectedPub(event.pub.id)
        }
    }
}