package com.uludi.pub.ui.addevent

import app.cash.molecule.RecompositionMode
import app.cash.molecule.launchMolecule
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.coroutines.coroutineScope
import com.uludi.business.common.services.EventsService
import com.uludi.business.pub.services.PubManagerService
import kotlinx.datetime.LocalDate
import org.koin.core.annotation.Factory
import org.koin.core.annotation.InjectedParam

data class AddEventComponentParams(
    val componentContext: ComponentContext,
    val date: LocalDate,
    val onBack: () -> Unit,
    val onClose: () -> Unit,
    val onEventAdded: () -> Unit,
)

@Factory
class AddEventComponent(
    @InjectedParam private val params: AddEventComponentParams,
    private val eventsService: EventsService,
    private val pubManagerService: PubManagerService,
) : ComponentContext by params.componentContext {
    val state = coroutineScope().launchMolecule(RecompositionMode.Immediate) {
        AddEventPresenter(
            date = params.date,
            eventsService = eventsService,
            pubManagerService = pubManagerService,
            onBack = { params.onBack() },
            onClose = { params.onClose() },
            onEventAdded = { params.onEventAdded() })
    }
}