package com.uludi.bard.components

import androidx.compose.animation.core.animateDpAsState
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Close
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.contentColorFor
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.Stable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.Saver
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp

enum class SideSheetValue {
    Closed,
    Open
}

@Suppress("NotCloseable")
@Stable
class SideSheetState(initialValue: SideSheetValue = SideSheetValue.Closed) {
    /**
     * Whether the drawer is open.
     */
    val isOpen: Boolean
        get() = currentValue.value == SideSheetValue.Open

    /**
     * Whether the drawer is closed.
     */
    val isClosed: Boolean
        get() = currentValue.value == SideSheetValue.Closed

    var currentValue: MutableState<SideSheetValue> = mutableStateOf(initialValue)

    fun open() {
        currentValue.value = SideSheetValue.Open
    }

    fun close() {
        currentValue.value = SideSheetValue.Closed
    }


    companion object {
        /**
         * The default [Saver] implementation for [SideSheetState].
         */
        fun Saver() =
            Saver<SideSheetState, SideSheetValue>(
                save = { it.currentValue.value },
                restore = { SideSheetState(it) }
            )
    }
}

@Composable
fun rememberSideSheetState(
    initialValue: SideSheetValue,
): SideSheetState {
    return rememberSaveable(saver = SideSheetState.Saver()) {
        SideSheetState(initialValue)
    }
}

@Composable
fun ModalSideSheet(
    modifier: Modifier = Modifier,
    sideSheetState: SideSheetState = rememberSideSheetState(SideSheetValue.Closed),
    scrimColor: Color = SideSheetDefaults.scrimColor,
    onDismissRequest: (() -> Unit)? = null,
    sheetContent: @Composable () -> Unit,
) {
    val offset by animateDpAsState(if (sideSheetState.isOpen) 0.dp else 400.dp)
    val scrimAlpha by animateFloatAsState(if (sideSheetState.isOpen) 1f else 0f)

    Box(
        modifier
            .fillMaxSize(),
        contentAlignment = Alignment.CenterEnd
    ) {
        Scrim(
            isOpen = sideSheetState.isOpen,
            alpha = scrimAlpha,
            color = scrimColor,
            onDismissRequest = onDismissRequest,
        )
        Box(
            Modifier
                .offset(x = offset)
        ) { sheetContent() }
    }
}

@Composable
fun SideSheet(
    modifier: Modifier = Modifier,
    windowInsets: WindowInsets = WindowInsets(0, 0, 0, 0),
    containerColor: Color = MaterialTheme.colorScheme.surfaceContainerLow,
    contentColor: Color = contentColorFor(containerColor),
    topBar: @Composable () -> Unit = {},
    content: @Composable ColumnScope.() -> Unit
) {
    Surface(
        modifier = modifier
            .width(SheetWidth)
            .fillMaxHeight(),
        shape = RoundedCornerShape(topStart = 16.dp, bottomStart = 16.dp),
        color = containerColor,
        contentColor = contentColor,
    ) {
        Column(
            Modifier
                .fillMaxWidth()
                .windowInsetsPadding(windowInsets),
        ) {
            topBar()
            content()
        }
    }
}

@Composable
fun SideSheetTopBar(
    title: @Composable () -> Unit,
    onDismiss: () -> Unit,
    navigationIcon: @Composable () -> Unit = {},
    modifier: Modifier = Modifier,
    showDivider: Boolean = true,
) {
    Column(modifier.fillMaxWidth()) {
        Row(
            Modifier.padding(top = 12.dp, end = 12.dp, bottom = 16.dp, start = 24.dp),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            CompositionLocalProvider(
                LocalContentColor provides MaterialTheme.colorScheme.onSurface
            ) {
                navigationIcon()
                CompositionLocalProvider(
                    LocalTextStyle provides MaterialTheme.typography.headlineSmall
                ) {
                    Box(Modifier.weight(1f)) {
                        title()
                    }
                }
            }
            IconButton(onDismiss) {
                Icon(
                    Icons.Default.Close,
                    tint = MaterialTheme.colorScheme.onSurfaceVariant,
                    contentDescription = null
                )
            }
        }
        if (showDivider) {
            HorizontalDivider(Modifier.fillMaxWidth())
        }
    }
}

/**
 * Object to hold default values for [ModalSideSheet]
 */
object SideSheetDefaults {

    /** Default color of the scrim that obscures content when the drawer is open */
    val scrimColor: Color
        @Composable get() = MaterialTheme.colorScheme.scrim.copy(alpha = 0.32f)
}

@Composable
private fun Scrim(
    isOpen: Boolean,
    alpha: Float,
    color: Color,
    onDismissRequest: (() -> Unit)? = null,
) {
    Canvas(
        Modifier
            .fillMaxSize()
            .then(if (isOpen) {
                Modifier.clickable(
                    enabled = onDismissRequest != null,
                    interactionSource = remember { MutableInteractionSource() },
                    indication = null,
                ) {
                    onDismissRequest?.invoke()
                }
            } else Modifier)

    ) {
        drawRect(color, alpha = alpha)
    }
}

private val SheetWidth = 400.dp

