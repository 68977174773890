package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val com_uludi_pub_ui_PubUiModule : Module = module {
	factory() { params -> com.uludi.pub.ui.addevent.AddEventComponent(params.get(),eventsService=get(),pubManagerService=get()) } bind(com.arkivanov.decompose.ComponentContext::class)
	factory() { params -> com.uludi.pub.ui.daydetails.DayDetailsComponent(params.get()) } binds(arrayOf(com.arkivanov.decompose.ComponentContext::class,org.koin.core.component.KoinComponent::class))
	factory() { params -> com.uludi.pub.ui.dayeventslist.DayEventsListComponent(params.get(),pubManagerService=get(),eventsService=get()) } bind(com.arkivanov.decompose.ComponentContext::class)
	factory() { params -> com.uludi.pub.ui.home.HomeComponent(params.get(),pubManagerService=get()) } binds(arrayOf(com.arkivanov.decompose.ComponentContext::class,org.koin.core.component.KoinComponent::class))
	factory() { params -> com.uludi.pub.ui.layout.PubLayoutComponent(params.get(),pubManagerService=get(),authService=get()) } binds(arrayOf(com.arkivanov.decompose.ComponentContext::class,org.koin.core.component.KoinComponent::class))
	factory() { params -> com.uludi.pub.ui.login.LoginComponent(params.get(),params.get(),authService=get()) } bind(com.arkivanov.decompose.ComponentContext::class)
}
public val com.uludi.pub.ui.PubUiModule.module : org.koin.core.module.Module get() = com_uludi_pub_ui_PubUiModule