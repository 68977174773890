package com.uludi.business.common

import co.touchlab.kermit.Logger
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import org.koin.core.annotation.Factory
import org.koin.core.annotation.Module
import org.koin.core.annotation.Named
import kotlin.coroutines.CoroutineContext

@Module
class CoroutinesModule {
    @Named("Default")
    @Factory
    fun provideIoCoroutineScope(): CoroutineScope =
        CoroutineScope(SupervisorJob() + Dispatchers.Default + CoroutineExceptionHandler { _, throwable ->
            Logger.e(throwable) { "Uncaught coroutine error" }
        })

    @Named("Main")
    @Factory
    fun provideMainCoroutineContext(): CoroutineContext =
        SupervisorJob() + Dispatchers.Main + CoroutineExceptionHandler { _, throwable ->
            Logger.e(throwable) { "Uncaught coroutine error" }
        }
}