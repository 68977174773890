package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val com_uludi_business_common_repositories_SupabaseModule : Module = module {
	val moduleInstance = com.uludi.business.common.repositories.SupabaseModule()
	single() { moduleInstance.provideSupabaseClient() } bind(io.github.jan.supabase.SupabaseClient::class)
}
public val com.uludi.business.common.repositories.SupabaseModule.module : org.koin.core.module.Module get() = com_uludi_business_common_repositories_SupabaseModule