package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val com_uludi_business_common_services_CommonServicesModule : Module = module {
	factory() { com.uludi.business.common.services.DefaultEventsService(supabase=get()) } binds(arrayOf(com.uludi.business.common.services.EventsService::class,com.uludi.business.common.repositories.SupabaseRepository::class))
	factory() { com.uludi.business.common.services.DefaultPubsService(supabase=get()) } binds(arrayOf(com.uludi.business.common.services.PubsService::class,com.uludi.business.common.repositories.SupabaseRepository::class))
}
public val com.uludi.business.common.services.CommonServicesModule.module : org.koin.core.module.Module get() = com_uludi_business_common_services_CommonServicesModule