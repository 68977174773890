package com.uludi.business.common

import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

private enum class AppEnvironment(val rawName: String) {
    Staging("staging"), Prod("prod"),
}

object EnvironmentDiTokens {
    const val AppEnvironment = "app-environment"
    const val AppIsDebug = "app-is-debug"
}

class Environment private constructor() : KoinComponent {

    private val appEnvironmentRaw by inject<String>(named(EnvironmentDiTokens.AppEnvironment))
//    private val isDebug by inject<Boolean>(named(EnvironmentDiTokens.AppIsDebug))

    private val appEnvironment =
        AppEnvironment.entries.first { it.rawName == appEnvironmentRaw.lowercase() }

    companion object {
        val shared: Environment by lazy {
            Environment()
        }
    }

    fun getSupabaseUrl() =
        when (appEnvironment) {
            AppEnvironment.Staging -> "https://vbchjpktzljokecygnud.supabase.co"
            AppEnvironment.Prod -> "https://jzmoisflqhpzyuzylmwh.supabase.co"
        }

    fun getSupabaseAnonKey() =
        when (appEnvironment) {
            AppEnvironment.Staging -> "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZiY2hqcGt0emxqb2tlY3lnbnVkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTIyNTQ5NjEsImV4cCI6MjAyNzgzMDk2MX0.YrBqGtAs1tPjLYZjoaFHo2NBUZthiJhdosLKGECj1vA"
            AppEnvironment.Prod -> "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imp6bW9pc2ZscWhwenl1enlsbXdoIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTQ5ODYzNDIsImV4cCI6MjAzMDU2MjM0Mn0.lUb0SRbCNdSF8ztaSIB7Io4kghDZk7d5PtNwrqNgckY"
        }

}