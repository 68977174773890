package com.uludi.pub.ui.login

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Visibility
import androidx.compose.material.icons.filled.VisibilityOff
import androidx.compose.material3.Button
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.LinearProgressIndicator
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.PasswordVisualTransformation
import androidx.compose.ui.text.input.VisualTransformation
import com.uludi.bard.components.Spacer
import com.uludi.bard.utils.moveFocusOnTab
import com.uludi.bard.utils.submitOnEnter
import com.uludi.common.resources.LocalCommonResources
import com.uludi.pub.ui.resources.LocalPubResources
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

data object LoginScreen {
    data class State(
        val email: String,
        val password: String,
        var passwordVisible: Boolean,
        val invalidCredentials: Boolean,
        val isLoading: Boolean,
        val eventSink: (UiEvent) -> Unit,
    )

    sealed class UiEvent {
        data object SubmitClicked : UiEvent()
        data class EmailChanged(val newEmail: String) : UiEvent()
        data class PasswordChanged(val newPassword: String) : UiEvent()
        data object PasswordVisibilityToggleClicked : UiEvent()
    }
}

@Composable
fun LoginSmartScreen(loginComponent: LoginComponent) {
    val state by loginComponent.state.collectAsState()
    LoginScreen(state)
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun LoginScreen(state: LoginScreen.State, modifier: Modifier = Modifier) {
    val commonResources = LocalCommonResources.current
    val pubResources = LocalPubResources.current

    fun submit() {
        state.eventSink(LoginScreen.UiEvent.SubmitClicked)
    }
    Surface(modifier) {
        Column(Modifier.fillMaxSize()) {
            AnimatedVisibility(state.isLoading) {
                LinearProgressIndicator(Modifier.fillMaxWidth())
            }
            Box(Modifier.fillMaxWidth().weight(1f), contentAlignment = Alignment.Center) {
                Column(
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally,
                    modifier = Modifier.width(IntrinsicSize.Min),
                ) {
                    Image(
                        painter = painterResource(commonResources.drawables.logo),
                        contentDescription = null,
                        modifier = Modifier.fillMaxWidth(0.5f),
                        contentScale = ContentScale.FillWidth,
                    )
                    Spacer.V.L()
                    OutlinedTextField(
                        value = state.email,
                        onValueChange = { state.eventSink(LoginScreen.UiEvent.EmailChanged(it)) },
                        label = { Text(stringResource(pubResources.strings.loginEmailPlaceholder)) },
                        isError = state.invalidCredentials,
                        modifier = Modifier
                            .moveFocusOnTab()
                            .submitOnEnter {
                                state.eventSink(LoginScreen.UiEvent.SubmitClicked)
                            },
                        keyboardOptions = KeyboardOptions(imeAction = ImeAction.Next),
                    )
                    Spacer.V.M()
                    OutlinedTextField(
                        value = state.password,
                        onValueChange = { state.eventSink(LoginScreen.UiEvent.PasswordChanged(it)) },
                        label = { Text(stringResource(pubResources.strings.loginPasswordPlaceholder)) },
                        visualTransformation =
                        if (state.passwordVisible) VisualTransformation.None
                        else PasswordVisualTransformation(),
                        isError = state.invalidCredentials,
                        modifier = Modifier
                            .moveFocusOnTab()
                            .submitOnEnter {
                                submit()
                            },
                        keyboardOptions = KeyboardOptions(imeAction = ImeAction.Next),
                        trailingIcon = {
                            IconButton(
                                onClick = {
                                    state.eventSink(LoginScreen.UiEvent.PasswordVisibilityToggleClicked)
                                }
                            ) {
                                Icon(
                                    if (state.passwordVisible) {
                                        Icons.Default.VisibilityOff
                                    } else {
                                        Icons.Default.Visibility
                                    },
                                    contentDescription = null
                                )
                            }
                        }
                    )
                    if (state.invalidCredentials) {
                        Spacer.V.M()
                        Text(
                            stringResource(resource = pubResources.strings.loginInvalidCredentialsText),
                            color = MaterialTheme.colorScheme.error
                        )
                    }
                    Spacer.V.M()
                    Button(
                        onClick = {
                            submit()
                        },
                        modifier = Modifier.fillMaxWidth(),
                        enabled = !state.isLoading
                    ) { Text(stringResource(pubResources.strings.loginSubmitButtonText)) }
                }
            }
        }

    }
}