package com.uludi.pub.ui.login

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import co.touchlab.kermit.Logger
import com.uludi.business.pub.services.AuthService
import kotlinx.coroutines.launch

@Composable
fun LoginPresenter(
    authService: AuthService,
    onLoggedIn: () -> Unit,
): LoginScreen.State {
    var email by rememberSaveable { mutableStateOf("") }
    var password by rememberSaveable { mutableStateOf("") }
    var invalidCredentials by rememberSaveable { mutableStateOf(false) }
    var signingIn by rememberSaveable { mutableStateOf(false) }
    var errorLoggingIn by rememberSaveable { mutableStateOf(false) }
    var passwordVisible by rememberSaveable { mutableStateOf(false) }

    val coroutineScope = rememberCoroutineScope()

    return LoginScreen.State(
        email = email,
        password = password,
        isLoading = signingIn,
        passwordVisible = passwordVisible,
        invalidCredentials = invalidCredentials,
    ) { event ->
        when (event) {
            LoginScreen.UiEvent.SubmitClicked -> coroutineScope.launch {
                if (signingIn) return@launch
                signingIn = true
                try {
                    val success = authService.signInWithEmail(email, password)
                    if (success) {
                        onLoggedIn()
                    } else {
                        invalidCredentials = true
                    }
                } catch (e: Exception) {
                    Logger.e(e) { "Error logging in" }
                    errorLoggingIn = true
                } finally {
                    signingIn = false
                }
            }

            is LoginScreen.UiEvent.EmailChanged -> {
                email = event.newEmail
            }

            is LoginScreen.UiEvent.PasswordChanged -> {
                password = event.newPassword
            }

            LoginScreen.UiEvent.PasswordVisibilityToggleClicked -> {
                passwordVisible = passwordVisible.not()
            }
        }

    }

}