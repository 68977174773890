package com.uludi.business.pub.services

import io.github.jan.supabase.SupabaseClient
import io.github.jan.supabase.exceptions.BadRequestRestException
import io.github.jan.supabase.gotrue.SessionStatus
import io.github.jan.supabase.gotrue.auth
import io.github.jan.supabase.gotrue.providers.builtin.Email
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.stateIn
import org.koin.core.annotation.Factory
import org.koin.core.annotation.Named

sealed class AuthStatus {
    data object Loading : AuthStatus()
    data class Authenticated(val id: String, val email: String) : AuthStatus()
    data object NotAuthenticated : AuthStatus()
    data object Error : AuthStatus()
}

@Factory
class AuthService(
    private val supabase: SupabaseClient,
    @Named("Default") val coroutineScope: CoroutineScope,
) {
    fun getAuthStateStream() =
        supabase.auth.sessionStatus
            .map {
                when (it) {
                    is SessionStatus.Authenticated -> AuthStatus.Authenticated(
                        id = it.session.user!!.id,
                        email = it.session.user?.email.orEmpty()
                    )

                    SessionStatus.LoadingFromStorage -> AuthStatus.Loading
                    SessionStatus.NetworkError -> AuthStatus.Error
                    is SessionStatus.NotAuthenticated -> AuthStatus.NotAuthenticated
                }
            }
            .stateIn(coroutineScope, SharingStarted.Lazily, AuthStatus.Loading)

    suspend fun signInWithEmail(email: String, password: String): Boolean =
        try {
            supabase.auth.signInWith(Email) {
                this@signInWith.email = email
                this@signInWith.password = password
            }
            true
        } catch (e: BadRequestRestException) {
            false
        }

    suspend fun signOut(): Boolean =
        try {
            supabase.auth.signOut()
            true
        } catch (e: BadRequestRestException) {
            false
        }
}