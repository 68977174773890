package com.uludi.business.common.repositories

import com.uludi.business.common.Environment
import io.github.jan.supabase.SupabaseClient
import io.github.jan.supabase.createSupabaseClient
import io.github.jan.supabase.gotrue.Auth
import io.github.jan.supabase.gotrue.PostgrestFilterDSL
import io.github.jan.supabase.postgrest.Postgrest
import io.github.jan.supabase.postgrest.PropertyConversionMethod
import io.github.jan.supabase.postgrest.from
import io.github.jan.supabase.postgrest.query.Columns
import io.github.jan.supabase.postgrest.query.PostgrestRequestBuilder
import io.github.jan.supabase.realtime.Realtime
import org.koin.core.annotation.Module
import org.koin.core.annotation.Single

@Module
class SupabaseModule {
    @Single
    fun provideSupabaseClient() =
        createSupabaseClient(
            supabaseUrl = Environment.shared.getSupabaseUrl(),
            supabaseKey = Environment.shared.getSupabaseAnonKey(),
        ) {
            install(Postgrest) {
                propertyConversionMethod = PropertyConversionMethod.CAMEL_CASE_TO_SNAKE_CASE
            }
            install(Auth)
            install(Realtime)
        }
}

open class SupabaseRepository(
    val supabase: SupabaseClient,
    val tableName: String,
) {
    suspend inline fun <reified T : Any> fetchList(
        columns: Columns = Columns.ALL,
        request: @PostgrestFilterDSL PostgrestRequestBuilder.() -> Unit = {}
    ): List<T> =
        supabase
            .from(tableName)
            .select(columns, request = request)
            .decodeList()

    suspend inline fun <reified T : Any> fetchOne(
        columns: Columns = Columns.ALL,
        request: @PostgrestFilterDSL PostgrestRequestBuilder.() -> Unit = {}
    ): T =
        supabase
            .from(tableName)
            .select(columns, request = request)
            .decodeSingle()
}