package com.uludi.pub.ui.dayeventslist

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.Warning
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Button
import androidx.compose.material3.ExtendedFloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.ListItem
import androidx.compose.material3.ListItemDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.uludi.bard.components.ErrorWidget
import com.uludi.bard.components.LoadingWidget
import com.uludi.bard.components.SideSheet
import com.uludi.bard.components.SideSheetTopBar
import com.uludi.business.common.services.Event
import com.uludi.pub.ui.dayeventslist.DayEventsListScreen.EventsState
import com.uludi.pub.ui.dayeventslist.DayEventsListScreen.State
import com.uludi.pub.ui.dayeventslist.DayEventsListScreen.UiEvent
import com.uludi.pub.ui.resources.LocalPubResources
import kotlinx.datetime.LocalDate
import kotlinx.datetime.format
import kotlinx.datetime.format.DateTimeComponents
import kotlinx.datetime.format.FormatStringsInDatetimeFormats
import kotlinx.datetime.format.byUnicodePattern
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.stringResource

data class DayEventsListScreen(
    val date: LocalDate,
) {
    data class State(
        val date: LocalDate,
        val eventsState: EventsState = EventsState.Loading,
        val eventsSink: (UiEvent) -> Unit,
    )

    sealed class EventsState {
        data object Loading : EventsState()
        data object Error : EventsState()
        data class Content(
            val events: List<Event>,
        ) : EventsState()
    }

    sealed class UiEvent {
        data object CloseClicked : UiEvent()
        data class EventDeleteClicked(val id: Int) : UiEvent()
        data object AddEventClicked : UiEvent()
        data object RetryClicked : UiEvent()
    }
}

@Composable
fun DayEventsListSmartScreen(
    component: DayEventsListComponent,
) {
    val state by component.state.collectAsState()
    DayEventsListScreen(state)
}

@OptIn(ExperimentalResourceApi::class, FormatStringsInDatetimeFormats::class)
@Composable
fun DayEventsListScreen(
    state: State,
    modifier: Modifier = Modifier,
) {
    val pubResources = LocalPubResources.current
    var eventToBeDeleted by remember { mutableStateOf<Int?>(null) }

    SideSheet(
        topBar = {
            SideSheetTopBar(
                title = {
                    Text(
                        stringResource(
                            pubResources.strings.dayDetailsTitle,
                            state.date.format(LocalDate.Format { byUnicodePattern("d/M") })
                        )
                    )
                },
                onDismiss = { state.eventsSink(UiEvent.CloseClicked) },
            )
        }
    ) {
        Box(modifier.fillMaxSize()) {
            Column(Modifier.fillMaxWidth()) {
                when (state.eventsState) {
                    EventsState.Loading -> LoadingWidget(Modifier.fillMaxSize())
                    EventsState.Error -> ErrorWidget {
                        state.eventsSink(UiEvent.RetryClicked)
                    }

                    is EventsState.Content ->
                        LazyColumn {
                            items(state.eventsState.events, { it.id }) { event ->
                                ListItem(
                                    headlineContent = {
                                        Text("${event.homeTeam.name} vs ${event.visitorTeam.name}")
                                    },
                                    leadingContent = {
                                        Text("⚽")
                                    },
                                    supportingContent = {
                                        Text(
                                            event.time.format(DateTimeComponents.Format {
                                                byUnicodePattern(
                                                    "H:mm"
                                                )
                                            })
                                        )
                                    },
                                    trailingContent = {
                                        IconButton({ eventToBeDeleted = event.id }) {
                                            Icon(Icons.Default.Delete, null)
                                        }
                                    },
                                    colors = ListItemDefaults.colors(
                                        containerColor = Color.Unspecified,
                                    )
                                )
                            }
                        }
                }
            }
            ExtendedFloatingActionButton(
                onClick = {
                    state.eventsSink(UiEvent.AddEventClicked)
                },
                icon = {
                    Icon(Icons.Default.Add, null)
                },
                text = {
                    Text(stringResource(pubResources.strings.dayDetailsAddEventButtonText))
                },
                modifier = Modifier.align(Alignment.BottomEnd).padding(16.dp)
            )
        }
        if (eventToBeDeleted != null) {
            DeleteEventDialog(
                onDelete = {
                    state.eventsSink(UiEvent.EventDeleteClicked(eventToBeDeleted!!))
                    eventToBeDeleted = null
                },
                onCancel = { eventToBeDeleted = null }
            )
        }
    }
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun DeleteEventDialog(onDelete: () -> Unit, onCancel: () -> Unit) {
    val pubResources = LocalPubResources.current
    AlertDialog(
        icon = { Icon(Icons.Default.Warning, null) },
        title = { Text(stringResource(pubResources.strings.dayDetailsDeleteEventDialogTitle)) },
        text = { Text(stringResource(pubResources.strings.dayDetailsDeleteEventDialogText)) },
        confirmButton = { Button(onDelete) { Text(stringResource(pubResources.strings.dayDetailsDeleteEventDialogConfirm)) } },
        dismissButton = { TextButton(onDelete) { Text(stringResource(pubResources.strings.dayDetailsDeleteEventDialogCancel)) } },
        onDismissRequest = onCancel
    )
}