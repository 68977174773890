package com.uludi.common.resources

//TODO: Note this is only needed because multimodule resources are not yet supported.

import androidx.compose.runtime.compositionLocalOf
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.StringResource

@ExperimentalResourceApi
val LocalCommonResources = compositionLocalOf<CommonResources> {
    error("No common resources provided")
}

@ExperimentalResourceApi
interface CommonResources {
    val drawables: Drawables
    val strings: Strings

    @ExperimentalResourceApi
    interface Drawables {
        val icon: DrawableResource
        val logo: DrawableResource
    }

    @ExperimentalResourceApi
    interface Strings {
        val errorMessage: StringResource
        val retryButtonLabel: StringResource
    }

}