package com.uludi.pub.app

import com.uludi.common.resources.CommonResources
import com.uludi.pub.ui.resources.PubResources
import org.jetbrains.compose.resources.ExperimentalResourceApi
import uludi.pub.app.generated.resources.Res
import uludi.pub.app.generated.resources.add_event_events_header
import uludi.pub.app.generated.resources.add_event_title
import uludi.pub.app.generated.resources.day_details_delete_add_event_button_text
import uludi.pub.app.generated.resources.day_details_delete_event_dialog_cancel
import uludi.pub.app.generated.resources.day_details_delete_event_dialog_confirm
import uludi.pub.app.generated.resources.day_details_delete_event_dialog_text
import uludi.pub.app.generated.resources.day_details_delete_event_dialog_title
import uludi.pub.app.generated.resources.day_details_title
import uludi.pub.app.generated.resources.error_message
import uludi.pub.app.generated.resources.icon
import uludi.pub.app.generated.resources.login_email_placeholder
import uludi.pub.app.generated.resources.login_invalid_credentials_text
import uludi.pub.app.generated.resources.login_password_placeholder
import uludi.pub.app.generated.resources.login_submit_button_text
import uludi.pub.app.generated.resources.logo
import uludi.pub.app.generated.resources.retry_button_label

@ExperimentalResourceApi
class DefaultCommonResources : CommonResources {
    override val drawables = object : CommonResources.Drawables {
        override val icon = Res.drawable.icon
        override val logo = Res.drawable.logo
    }
    override val strings = object : CommonResources.Strings {
        override val errorMessage = Res.string.error_message
        override val retryButtonLabel = Res.string.retry_button_label
    }
}

@ExperimentalResourceApi
class DefaultPubResources : PubResources {
    override val strings = object : PubResources.Strings {
        override val loginEmailPlaceholder = Res.string.login_email_placeholder
        override val loginPasswordPlaceholder = Res.string.login_password_placeholder
        override val loginSubmitButtonText = Res.string.login_submit_button_text
        override val loginInvalidCredentialsText = Res.string.login_invalid_credentials_text
        override val dayDetailsTitle = Res.string.day_details_title
        override val dayDetailsDeleteEventDialogTitle =
            Res.string.day_details_delete_event_dialog_title
        override val dayDetailsDeleteEventDialogText =
            Res.string.day_details_delete_event_dialog_text
        override val dayDetailsDeleteEventDialogConfirm =
            Res.string.day_details_delete_event_dialog_confirm
        override val dayDetailsDeleteEventDialogCancel =
            Res.string.day_details_delete_event_dialog_cancel
        override val dayDetailsAddEventButtonText =
            Res.string.day_details_delete_add_event_button_text
        override val addEventTitle = Res.string.add_event_title
        override val addEventEventsHeader = Res.string.add_event_events_header
    }
}