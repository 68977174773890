package com.uludi.pub

import kotlin.Boolean
import kotlin.String

internal object BuildKonfig {
  public val isDebug: Boolean = false

  public val environment: String = "prod"
}
