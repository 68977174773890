package com.uludi.pub.ui.daydetails

import androidx.compose.runtime.Stable
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.pop
import com.arkivanov.decompose.router.stack.push
import com.arkivanov.decompose.value.Value
import com.uludi.pub.ui.addevent.AddEventComponent
import com.uludi.pub.ui.addevent.AddEventComponentParams
import com.uludi.pub.ui.dayeventslist.DayEventsListComponent
import com.uludi.pub.ui.dayeventslist.DayEventsListComponentParams
import io.wojciechosak.calendar.utils.today
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable
import org.koin.core.annotation.Factory
import org.koin.core.annotation.InjectedParam
import org.koin.core.component.KoinComponent
import org.koin.core.component.get
import org.koin.core.parameter.parametersOf

data class DayDetailsComponentParams(
    val componentContext: ComponentContext,
    val date: LocalDate,
    val onClose: () -> Unit,
)

@Factory
class DayDetailsComponent(
    @InjectedParam private val params: DayDetailsComponentParams,
) : ComponentContext by params.componentContext, KoinComponent {
    private val navigation = StackNavigation<Configuration>()

    @Stable //TODO: Compiler bug: https://youtrack.jetbrains.com/issue/KT-67330
    sealed class Child {
        class EventsList(val component: DayEventsListComponent) : Child()
        class AddEvent(val component: AddEventComponent) : Child()
    }

    val childStack: Value<ChildStack<*, Child>> =
        childStack(
            source = navigation,
            initialConfiguration = Configuration.EventsList(params.date),
            serializer = Configuration.serializer(),
        ) { config, childComponentContext ->
            when (config) {
                is Configuration.EventsList -> Child.EventsList(
                    get {
                        parametersOf(
                            DayEventsListComponentParams(
                                componentContext = childComponentContext,
                                date = config.date,
                                onClose = { params.onClose() },
                                onAddEventClick = { navigation.push(Configuration.AddEvent(config.date)) },
                            )
                        )
                    }
                )

                is Configuration.AddEvent -> Child.AddEvent(
                    get {
                        parametersOf(
                            AddEventComponentParams(
                                componentContext = childComponentContext,
                                date = config.date,
                                onBack = { navigation.pop() },
                                onClose = { params.onClose() },
                                onEventAdded = { navigation.pop() },
                            )
                        )
                    }
                )
            }
        }

    @Serializable
    internal sealed class Configuration {
        @Serializable
        data class EventsList(val date: LocalDate) : Configuration()
        data class AddEvent(val date: LocalDate) : Configuration()
    }
}