package com.uludi.pub.ui.addevent

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.produceState
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshotFlow
import co.touchlab.kermit.Logger
import com.uludi.business.common.services.EventsService
import com.uludi.business.pub.services.PubManagerService
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDate

@Composable
fun AddEventPresenter(
    date: LocalDate,
    eventsService: EventsService,
    pubManagerService: PubManagerService,
    onBack: () -> Unit,
    onClose: () -> Unit,
    onEventAdded: () -> Unit,
): AddEventScreen.State {
    var isLoading by rememberSaveable { mutableStateOf(true) }
    val coroutineScope = rememberCoroutineScope()

    val eventsState by produceState<AddEventScreen.EventsState>(AddEventScreen.EventsState.Loading) {
        snapshotFlow { isLoading }
            .filter { it }
            .onEach {
                value = AddEventScreen.EventsState.Loading
                isLoading = false
                try {
                    val events = eventsService.fetchDateEvents(date)
                    value = AddEventScreen.EventsState.Content(events)
                } catch (e: Exception) {
                    Logger.e(e) { "Error fetching events" }
                    value = AddEventScreen.EventsState.Error
                }
            }.collect()
    }


    return AddEventScreen.State(
        date = date,
        eventsState = eventsState
    ) { event ->
        when (event) {
            AddEventScreen.UiEvent.BackClicked -> onBack()
            AddEventScreen.UiEvent.CloseClicked -> onClose()

            is AddEventScreen.UiEvent.EventClicked -> {
                coroutineScope.launch {
                    val pubId =
                        pubManagerService.getSelectedPubIdStream().first() ?: return@launch
                    eventsService.addPubEvent(pubId, event.id)
                    onEventAdded()
                }
            }

            AddEventScreen.UiEvent.RetryLoadingClicked -> isLoading = true
        }
    }
}