@file:OptIn(ExperimentalCoroutinesApi::class)

package com.uludi.business.pub.services

import com.russhwolf.settings.Settings
import com.uludi.business.common.LoadState
import com.uludi.business.common.asLoadState
import com.uludi.business.common.services.Event
import com.uludi.business.common.services.EventsService
import com.uludi.business.common.services.Pub
import com.uludi.business.common.services.PubsService
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filterIsInstance
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.flatMapLatest
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.flow.onStart
import kotlinx.coroutines.flow.stateIn
import org.koin.core.annotation.Named
import org.koin.core.annotation.Single

data class PubManager(
    val email: String,
    val pubs: List<Pub>,
    val selectedPub: Pub,
)

@Single
class PubManagerService(
    private val authService: AuthService,
    private val pubsService: PubsService,
    private val eventsService: EventsService,
    private val settings: Settings,
    @Named("Default") val coroutineScope: CoroutineScope,
) {
    companion object {
        const val SelectedPubSettingsKey = "selected-pub"
    }

    /**
     * null means not authenticated
     */
    fun getPubManagerStream(): StateFlow<LoadState<PubManager>?> = manager

    fun getSelectedPubIdStream(): Flow<Int?> =
        getAuthenticatedStream().flatMapLatest { selectedPub }

    fun getSelectedPubEventsStream(): StateFlow<LoadState<List<Event>>> = events

    fun setSelectedPub(id: Int) {
        settings.putInt(SelectedPubSettingsKey, id)
        selectedPub.value = id
    }

    suspend fun retryEventLoad() {
        eventsLoadTrigger.emit(Unit)
    }

    private val selectedPub: MutableStateFlow<Int?> =
        MutableStateFlow(settings.getIntOrNull(SelectedPubSettingsKey))

    private fun getAuthenticatedStream() =
        authService.getAuthStateStream().filterIsInstance<AuthStatus.Authenticated>()

    private val manager =
        getAuthenticatedStream()
            .flatMapLatest { user ->
                combine(
                    getSelectedPubIdStream(),
                    flowOf(pubsService.fetchPubsByManager(user.id)).onEach { pubs ->
                        if (selectedPub.value == null) {
                            setSelectedPub(pubs.first().id)
                        }
                    }
                ) { selectedPubId, pubs ->
                    PubManager(
                        email = user.email,
                        pubs = pubs,
                        selectedPub = pubs.first { it.id == selectedPubId }
                    )
                }.asLoadState()
            }
            .stateIn(coroutineScope, SharingStarted.Lazily, null)

    private val eventsLoadTrigger = MutableSharedFlow<Unit>()
    private val events =
        eventsLoadTrigger
            .onStart { emit(Unit) }
            .flatMapLatest { getSelectedPubIdStream() }
            .filterNotNull()
            .flatMapLatest { pubId ->
                eventsService
                    .getEventsByPubStream(pubId)
                    .asLoadState()
            }.stateIn(coroutineScope, SharingStarted.Lazily, LoadState.Loading)
}