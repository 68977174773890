package com.uludi.business.common.services

import com.uludi.business.common.repositories.SupabaseRepository
import io.github.jan.supabase.SupabaseClient
import io.github.jan.supabase.postgrest.postgrest
import io.github.jan.supabase.postgrest.query.Columns
import io.github.jan.supabase.postgrest.rpc
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.koin.core.annotation.Factory

// region Interface
interface PubsService {
    suspend fun fetchPubsByEventSortedByDistance(
        eventId: Int,
        location: Location?
    ): List<PubWithDistance>

    suspend fun fetchPubsByManager(
        managerId: String
    ): List<Pub>
}
// endregion

// region Model
@Serializable
data class PubWithDistance(
    @SerialName("id")
    val id: Int,
    @SerialName("name")
    val name: String,
    @SerialName("icon_url")
    val iconUrl: String?,
    @SerialName("latitude")
    val latitude: Double,
    @SerialName("longitude")
    val longitude: Double,
    @SerialName("distance")
    val distance: Float?,
)

@Serializable
data class Pub(
    @SerialName("id")
    val id: Int,
    @SerialName("name")
    val name: String,
    @SerialName("icon_url")
    val iconUrl: String?,
)
//endregion

//region Implementation
@Factory
class DefaultPubsService(
    supabase: SupabaseClient
) : PubsService, SupabaseRepository(supabase, "pub") {
    override suspend fun fetchPubsByEventSortedByDistance(
        eventId: Int,
        location: Location?
    ): List<PubWithDistance> =
        if (location != null) {
            supabase.postgrest.rpc(
                function = "get_pubs_by_event_sorted_by_distance",
                parameters =
                buildJsonObject {
                    put("latitude", location.latitude)
                    put("longitude", location.longitude)
                    put("event_id", eventId)
                }
            ).decodeList()
        } else {
            supabase.postgrest.rpc(
                function = "get_pubs_by_event",
                parameters = buildJsonObject {
                    put("event_id", eventId)
                }
            ).decodeList()
        }

    override suspend fun fetchPubsByManager(managerId: String): List<Pub> =
        fetchList(
            Columns.raw(
                """
                id,
                name,
                icon_url,
                pub_admin(*)
                """
            )
        ) {
            filter {
                eq("pub_admin.user", managerId)
            }
        }
}
//endregion