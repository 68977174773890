package com.uludi.common.ui.mappers

import com.uludi.bard.components.EventInfo
import com.uludi.business.common.services.Event
import com.uludi.business.common.services.League
import com.uludi.business.common.services.Team

fun Event.toEventInfo() =
    EventInfo(
        homeTeam = homeTeam.toEventInfoTeam(),
        visitorTeam = visitorTeam.toEventInfoTeam(),
        league = league.toEventInfoLeague(),
        time = time,
    )

fun League.toEventInfoLeague() =
    EventInfo.League(
        name = name,
        logoUrl = logoUrl,
    )

fun Team.toEventInfoTeam() =
    EventInfo.Team(
        name = name,
        crestUrl = crestUrl,
    )