package com.uludi.bard.components

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.material3.Button
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.uludi.common.resources.LocalCommonResources
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.stringResource

@OptIn(ExperimentalResourceApi::class)
@Composable
fun ErrorWidget(modifier: Modifier = Modifier, onRetry: () -> Unit) {
    val resources = LocalCommonResources.current
    Column(
        modifier,
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        Text(
            stringResource(resources.strings.errorMessage),
            color = MaterialTheme.colorScheme.error
        )
        Spacer.V.S()
        Button({
            onRetry()
        }) {
            Text(stringResource(resources.strings.retryButtonLabel))
        }
    }
}