package com.uludi.bard.components

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import coil3.compose.AsyncImage
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.format
import kotlinx.datetime.format.FormatStringsInDatetimeFormats
import kotlinx.datetime.format.byUnicodePattern
import kotlinx.datetime.toLocalDateTime

data class EventInfo(
    val homeTeam: Team,
    val visitorTeam: Team,
    val time: Instant,
    val league: League,
) {
    data class Team(
        val name: String,
        val crestUrl: String,
    )

    data class League(
        val name: String,
        val logoUrl: String,
    )
}

@OptIn(FormatStringsInDatetimeFormats::class)
@Composable
fun Event(
    info: EventInfo,
    modifier: Modifier = Modifier,
    onClick: (() -> Unit)? = null,
) {
    val time = remember(info.time) {
        info.time.toLocalDateTime(TimeZone.currentSystemDefault()).format(
            LocalDateTime.Format { byUnicodePattern("d/M · H:mm") }
        )
    }
    Column(modifier
        .clickable(onClick != null) { onClick?.invoke() }
        .padding(vertical = 12.dp, horizontal = 16.dp)
    ) {
        Row(
            Modifier.fillMaxWidth().height(IntrinsicSize.Min),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            Text("⚽️", style = MaterialTheme.typography.labelSmall)
            AsyncImage(
                info.league.logoUrl,
                contentDescription = null,
                modifier = Modifier.fillMaxHeight()
            )
            Text("🕘 $time", textAlign = TextAlign.End, modifier = Modifier.fillMaxWidth())
        }
        Row(Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically) {
            Team(info.homeTeam, Modifier.padding(vertical = 10.dp).weight(1f))
            Text("vs", style = MaterialTheme.typography.bodyMedium)
            Team(info.visitorTeam, Modifier.padding(vertical = 10.dp).weight(1f))
        }
    }
}

@Composable
private fun Team(team: EventInfo.Team, modifier: Modifier = Modifier) {
    Column(
        modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        AsyncImage(
            team.crestUrl,
            contentDescription = null,
            modifier = Modifier.size(48.dp)
        )
        Text(team.name, style = MaterialTheme.typography.bodySmall)
    }
}