package com.uludi.pub.ui.layout

import androidx.compose.runtime.Stable
import app.cash.molecule.RecompositionMode
import app.cash.molecule.launchMolecule
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.lifecycle.coroutines.coroutineScope
import com.uludi.business.pub.services.AuthService
import com.uludi.business.pub.services.PubManagerService
import com.uludi.pub.ui.home.HomeComponent
import kotlinx.serialization.Serializable
import org.koin.core.annotation.Factory
import org.koin.core.annotation.InjectedParam
import org.koin.core.component.KoinComponent
import org.koin.core.component.get
import org.koin.core.parameter.parametersOf

@Factory
class PubLayoutComponent(
    @InjectedParam componentContext: ComponentContext,
    private val pubManagerService: PubManagerService,
    private val authService: AuthService,
) : ComponentContext by componentContext, KoinComponent {
    private val navigation = StackNavigation<Configuration>()

    @Stable //TODO: Compiler bug: https://youtrack.jetbrains.com/issue/KT-67330
    sealed class Child {
        class Home(val component: HomeComponent) : Child()
    }

    val childStack: Value<ChildStack<*, Child>> =
        childStack(
            source = navigation,
            initialConfiguration = Configuration.Home,
            serializer = Configuration.serializer(),
        ) { config, childComponentContext ->
            when (config) {
                Configuration.Home -> Child.Home(
                    get {
                        parametersOf(childComponentContext)
                    }
                )
            }
        }

    val state = coroutineScope().launchMolecule(RecompositionMode.Immediate) {
        PubLayoutPresenter(pubManagerService, authService)
    }

    @Serializable
    private sealed class Configuration {
        @Serializable
        data object Home : Configuration()
    }
}