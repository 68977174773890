package com.uludi.pub.app

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.safeDrawingPadding
import androidx.compose.foundation.layout.width
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.DefaultComponentContext
import com.arkivanov.decompose.extensions.compose.subscribeAsState
import com.arkivanov.essenty.lifecycle.LifecycleRegistry
import com.uludi.bard.theme.BardTheme
import com.uludi.business.common.CoroutinesModule
import com.uludi.business.common.EnvironmentDiTokens
import com.uludi.business.common.repositories.SupabaseModule
import com.uludi.business.common.services.CommonServicesModule
import com.uludi.business.pub.services.PubServicesModule
import com.uludi.common.resources.LocalCommonResources
import com.uludi.pub.app.root.PubRootComponent
import com.uludi.pub.ui.PubUiModule
import com.uludi.pub.ui.layout.PubSmartLayout
import com.uludi.pub.ui.login.LoginSmartScreen
import com.uludi.pub.ui.resources.LocalPubResources
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.koin.compose.KoinApplication
import org.koin.compose.koinInject
import org.koin.core.KoinApplication
import org.koin.core.annotation.ComponentScan
import org.koin.core.annotation.Module
import org.koin.core.parameter.parametersOf
import org.koin.core.qualifier.named
import org.koin.dsl.module
import org.koin.ksp.generated.module

@Composable
fun PubApp(
    environment: String,
    isDebug: Boolean,
) {
    PubApp(environment, isDebug) {}
}

@OptIn(ExperimentalResourceApi::class)
@Composable
fun PubApp(
    environment: String,
    isDebug: Boolean,
    rootComponentContext: ComponentContext = DefaultComponentContext(LifecycleRegistry()),
    appDeclaration: KoinApplication.() -> Unit,
) {
    KoinApplication({
        appDeclaration()
        modules(
            RootModule().module,
            CoroutinesModule().module,
            SupabaseModule().module,
            CommonServicesModule().module,
            PubUiModule().module,
            PubServicesModule().module,
            module {
                factory(named(EnvironmentDiTokens.AppEnvironment)) { environment }
                factory(named(EnvironmentDiTokens.AppIsDebug)) { isDebug }
            }
        )
    }) {
        val pubRootComponent = koinInject<PubRootComponent> {
            parametersOf(
                rootComponentContext,
            )
        }
        val childSlot by pubRootComponent.childSlot.subscribeAsState()
        val child by derivedStateOf { childSlot.child?.instance }
        BardTheme {
            CompositionLocalProvider(
                LocalCommonResources provides DefaultCommonResources(),
                LocalPubResources provides DefaultPubResources(),
            ) {
                Content(child)
            }
        }
    }
}

@Composable
fun Content(child: PubRootComponent.Child?) {
    when (child) {
        is PubRootComponent.Child.Login -> LoginSmartScreen(child.component)
        is PubRootComponent.Child.Layout -> PubSmartLayout(child.component)
        null -> SplashScreen()
    }
}


@OptIn(ExperimentalResourceApi::class)
@Composable
fun SplashScreen() {
    val commonResources = LocalCommonResources.current
    Box(
        Modifier.fillMaxSize().safeDrawingPadding(),
        contentAlignment = Alignment.Center,
    ) {
        Image(
            painter = painterResource(commonResources.drawables.icon),
            modifier = Modifier
                .width(86.dp)
                .height(120.dp),
            contentDescription = null,
        )
    }
}

@Module
@ComponentScan
class RootModule