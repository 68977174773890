package com.uludi.business.common.services

import co.touchlab.kermit.Logger
import com.uludi.business.common.repositories.SupabaseRepository
import io.github.jan.supabase.SupabaseClient
import io.github.jan.supabase.postgrest.postgrest
import io.github.jan.supabase.postgrest.query.Columns
import io.github.jan.supabase.postgrest.query.Order
import io.github.jan.supabase.postgrest.query.filter.FilterOperation
import io.github.jan.supabase.postgrest.query.filter.FilterOperator
import io.github.jan.supabase.postgrest.rpc
import io.github.jan.supabase.realtime.channel
import io.github.jan.supabase.realtime.postgresListDataFlow
import io.github.jan.supabase.realtime.realtime
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onStart
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atStartOfDayIn
import kotlinx.datetime.plus
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.koin.core.annotation.Factory

// region Interface
/**
 * Events service, source of truth regarding events
 */
interface EventsService {

    /**
     * Today events
     */
    suspend fun fetchTodayEvents(): List<Event>
    suspend fun fetchDateEvents(date: LocalDate): List<Event>
    suspend fun searchEvents(query: String): List<Event>
    suspend fun fetchEventById(id: Int): Event
    fun getEventsByPubStream(pubId: Int): Flow<List<Event>>
    suspend fun deletePubEvent(pubId: Int, eventId: Int)
    suspend fun addPubEvent(pubId: Int, eventId: Int)
}
// endregion

// region Model
@Serializable
data class Event(
    @SerialName("id")
    val id: Int,
    @SerialName("home_team")
    val homeTeam: Team,
    @SerialName("visitor_team")
    val visitorTeam: Team,
    @SerialName("time")
    val time: Instant,
    @SerialName("league")
    val league: League,
) {
    val date: LocalDate = time.toLocalDateTime(TimeZone.currentSystemDefault()).date
}

@Serializable
data class League(
    @SerialName("name")
    val name: String,
    @SerialName("logo_url")
    val logoUrl: String,
)

@Serializable
data class Team(
    @SerialName("name")
    val name: String,
    @SerialName("crest_url")
    val crestUrl: String,
)
//endregion

//region Implementation
@Factory
class DefaultEventsService(
    supabase: SupabaseClient
) : EventsService, SupabaseRepository(supabase, "event") {

    companion object {
        private val EventColumns = Columns.raw(
            """
            id,
            time,
            league(*),
            home_team(*),
            visitor_team(*)
            """
        )
    }

    override suspend fun fetchTodayEvents(): List<Event> =
        fetchDateEvents(Clock.System.now().toLocalDateTime(TimeZone.UTC).date)

    override suspend fun fetchDateEvents(date: LocalDate): List<Event> {
        val dateAtMidnight = date.atStartOfDayIn(TimeZone.UTC)
        val followingDayAtMidnight = date.plus(1, DateTimeUnit.DAY).atStartOfDayIn(TimeZone.UTC)

        return fetchList<Event>(columns = EventColumns) {
            filter {
                and {
                    Event::time gte dateAtMidnight
                    Event::time lt followingDayAtMidnight
                }
            }
            order("time", Order.ASCENDING)
            limit(100L)
        }
    }

    override suspend fun searchEvents(query: String): List<Event> {
        //Need a database function because PostgREST doesn't support filtering by several joined columns in an 'or'
        return supabase.postgrest.rpc(
            function = "search_event_by_home_or_visitor_name",
            parameters = buildJsonObject {
                put("query", query)
            },
        ).decodeList()
    }

    override suspend fun fetchEventById(id: Int): Event =
        fetchOne(columns = EventColumns) {
            filter {
                Event::id eq id
            }
        }

    @Serializable
    private data class PubEvent(
        @SerialName("pub")
        val pub: Int,
        @SerialName("event")
        val event: Int,
    )

    override fun getEventsByPubStream(pubId: Int): Flow<List<Event>> {
        val channel = supabase.realtime.channel("${pubId}_events")
        return channel.postgresListDataFlow(
            table = "pub_event",
            primaryKey = PubEvent::event,
            filter = FilterOperation("pub", FilterOperator.EQ, pubId)
        )
            .onStart { channel.subscribe() }
            .map { pubEvents ->
                fetchList(columns = EventColumns) {
                    filter {
                        Event::id isIn pubEvents.map { it.event }
                    }
                }
            }
    }

    override suspend fun deletePubEvent(pubId: Int, eventId: Int) {
        supabase.postgrest.from("pub_event")
            .delete {
                filter {
                    eq("pub", pubId)
                    eq("event", eventId)
                }
            }
    }

    override suspend fun addPubEvent(pubId: Int, eventId: Int) {
        supabase.postgrest.from("pub_event")
            .insert(PubEvent(pub = pubId, event = eventId))
    }
}
//endregion