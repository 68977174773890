package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val com_uludi_business_pub_services_PubServicesModule : Module = module {
	val moduleInstance = com.uludi.business.pub.services.PubServicesModule()
	factory() { moduleInstance.provideSettings() } bind(com.russhwolf.settings.Settings::class)
	single() { com.uludi.business.pub.services.PubManagerService(authService=get(),pubsService=get(),eventsService=get(),settings=get(),coroutineScope=get(qualifier=org.koin.core.qualifier.StringQualifier("Default"))) } 
	factory() { com.uludi.business.pub.services.AuthService(supabase=get(),coroutineScope=get(qualifier=org.koin.core.qualifier.StringQualifier("Default"))) } 
}
public val com.uludi.business.pub.services.PubServicesModule.module : org.koin.core.module.Module get() = com_uludi_business_pub_services_PubServicesModule