package com.uludi.pub.app.root

import androidx.compose.runtime.Stable
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.slot.ChildSlot
import com.arkivanov.decompose.router.slot.SlotNavigation
import com.arkivanov.decompose.router.slot.activate
import com.arkivanov.decompose.router.slot.childSlot
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.lifecycle.coroutines.coroutineScope
import com.uludi.business.pub.services.AuthService
import com.uludi.business.pub.services.AuthStatus
import com.uludi.pub.ui.layout.PubLayoutComponent
import com.uludi.pub.ui.login.LoginComponent
import com.uludi.pub.ui.login.LoginComponentParams
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.serialization.Serializable
import org.koin.core.annotation.InjectedParam
import org.koin.core.annotation.Single
import org.koin.core.component.KoinComponent
import org.koin.core.component.get
import org.koin.core.parameter.parametersOf

@Single
class PubRootComponent(
    @InjectedParam componentContext: ComponentContext,
    authService: AuthService,
) : ComponentContext by componentContext, KoinComponent {

    @Stable //TODO: Compiler bug: https://youtrack.jetbrains.com/issue/KT-67330
    sealed class Child {
        class Login(val component: LoginComponent) : Child()
        class Layout(val component: PubLayoutComponent) : Child()
    }

    private val navigation = SlotNavigation<Configuration>()
    private val scope = coroutineScope()

    val childSlot: Value<ChildSlot<*, Child>> =
        childSlot(
            source = navigation,
            serializer = Configuration.serializer(),
        ) { config, childComponentContext ->
            when (config) {
                Configuration.Login -> Child.Login(
                    get {
                        parametersOf(childComponentContext, LoginComponentParams(
                            onLoggedIn = {
                                navigation.activate(Configuration.Layout)
                            }
                        ))
                    }
                )

                Configuration.Layout -> Child.Layout(
                    get { parametersOf(childComponentContext) }
                )
            }
        }

    init {
        authService
            .getAuthStateStream()
            .onEach { authStatus ->
                if (authStatus is AuthStatus.Authenticated) {
                    navigation.activate(Configuration.Layout)
                } else if (authStatus is AuthStatus.NotAuthenticated) {
                    navigation.activate(Configuration.Login)
                }
            }
            .launchIn(scope)
    }

    @Serializable
    private sealed class Configuration {
        @Serializable
        data object Login : Configuration()

        @Serializable
        data object Layout : Configuration()
    }
}