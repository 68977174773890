package com.uludi.business.common

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.catch
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onStart

sealed interface LoadState<out T> {
    data class Success<T>(val data: T) : LoadState<T>
    data class Error(val exception: Throwable) : LoadState<Nothing>
    data object Loading : LoadState<Nothing>
}

fun <T> Flow<T>.asLoadState(): Flow<LoadState<T>> {
    return this
        .map<T, LoadState<T>> { LoadState.Success(it) }
        .onStart { emit(LoadState.Loading) }
        .catch { emit(LoadState.Error(it)) }
}